import React, { useState } from "react";
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer";
import { Container } from "react-bootstrap";
import Section from "../../Component/Section";
import HomeProduct from "../../Component/HomeProduct";
import CategoryProduct from "../../Component/CategoryProduct";
export default function Index() {
  return (
    <Container fluid style={{ padding: "0px", margin: "0px" }}>
      <Header />
      <Section title={"Top Offer"} />
      <Container fluid style={{ marginTop: "220px" }}>
        <HomeProduct
          title={"Top Offer"}
          description={
            "Brilliant reasons Aladdin Adventure should be your one-stop-travel-partner!"
          }
        />
      </Container>
      <Footer />
    </Container>
  );
}
