import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../Component/Header";
import Slider from "../../Component/Slider";
import Category from "../../Component/Category";
import HomeProduct from "../../Component/HomeProduct/index";
import Newsletter from "../../Component/Newsletter/index";
import Help from "../../Component/Help/index";
import cta from "../../images/cta.png";
import Testimonials from "../../Component/Testimonials";
import Footer from "../../Component/Footer/index";
import "./index.css";
import { BrowserView, MobileView } from "react-device-detect";
export default function Index() {
  return (
    <Container fluid>
      <Header />
      <Slider />
      <Category />
      <div style={{ minHeight: "800px" }}>
        <HomeProduct
          title={"Trending 2024"}
          description={
            "Brilliant reasons Aladdin Adventure should be your one-stop-travel-partner!"
          }
        />
      </div>
      <div style={{ minHeight: "800px", marginTop: "80px" }}>
        <HomeProduct
          title={"Dinner Cruise Offers"}
          description={
            "Brilliant reasons Aladdin Adventure should be your one-stop-travel-partner!"
          }
        />
      </div>
      <div style={{ marginTop: "80px" }}>
        <HomeProduct
          title={"Desert Safari Offers"}
          description={
            "Brilliant reasons Aladdin Adventure should be your one-stop-travel-partner!"
          }
        />
      </div>

      <Container className="container-cta"></Container>
      <Container fluid style={{ minHeight: "500px" }}>
        <Newsletter />
      </Container>
      <Container fluid style={{ marginTop: "150px" }}>
        <Help />
      </Container>
      <Container fluid style={{ marginTop: "150px" }}>
        <Testimonials
          title={"Our Recent Reviews"}
          description={"what our costumers says about us"}
        />
      </Container>
      <BrowserView>
        <Footer />
      </BrowserView>
      <MobileView>
        <Container>
          <Footer />
        </Container>
      </MobileView>
    </Container>
  );
}
