import React, { useState } from "react";
import one from "../../images/one.png";
import two from "../../images/two.png";
import three from "../../images/three.png";
import four from "../../images/four.png";
import wishlist from "../../images/wishlist.png";
import star from "../../images/star.png";
import clock from "../../images/clock.png";
import checkmark from "../../images/checkmark.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./index.css";
import { Button } from "antd";
import badge from "../../images/badge.png";
import { Card, Divider } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function Index(props) {
  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      badge: badge,
      name: "Vishal Mistry",
      title: "Exceptional",
      time: "A year ago",
      review:
        "Was on a vaccation with my family at Dubai this May. Tour,Tickets and transfers were very well managed by Aladdin Staff.We use to get tickets and updates on whatsapp early before tour.. Special thanks to Mr. Hari for his advise and guidance we explored a lot in Dubai.. I surely recommend Aladdin Adventure Travels company for SIC or PVT tours with family and Groups And again thanks Mr. Hari for making our Dubai stay memorable !!!",
    },
    {
      id: 2,
      badge: badge,
      name: "Janardhan Poojari",
      title: "Exceptional",
      time: "A year ago",
      review:
        "As a family, we have booked a short break in Dubai with several hotels and Aladdin travels organized us all transfers and  Everything has worked perfectly and services where excellent Aladdin travels is always mobile accessible.If there is a next time, we would certainly book some tours with Aladdin travels",
    },
    {
      id: 3,
      badge: badge,
      name: "Mahesh Mohan",
      title: "Exceptional",
      time: "A year ago",
      review:
        "Really appreciating your team for providing us well versed tour package and it's priceless moments for us .Will choose you guys on our next vacation. Stay blessed",
    },
    {
      id: 4,
      badge: badge,
      name: "Nirav Mehta",
      title: "Exceptional",
      time: "A year ago",
      review:
        "I have choosen a nice tourism company for my family and we all are very happy and guide is also good and food and hotel stay was also awesome and family also enjoyed with your company ❤️❤️❤️    😊😊😊😊 the company has supported and staff driver's are also good the Good respect we have got from them . I tell to everyone for our family this is the nice company for us.",
    },
  ]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    console.log(rest);
    return (
      <div className="carousel-button-group">
        {" "}
        <Button
          className={currentSlide === 0 ? "disable" : "enable"}
          onClick={() => previous()}
        >
          {"<"}
        </Button>
        <Button className="enable" onClick={() => next()}>
          {">"}
        </Button>
      </div>
    );
  };
  return (
    <>
      <BrowserView>
        <Container className="category-container">
          <div>
            <h1>{props.title}</h1>
            <p>{props.description}</p>
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-product"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            itemClass="carousel-item-padding-40-px"
            customButtonGroup={<ButtonGroup />}
            autoPlay={true}
          >
            {testimonials.map((item) => {
              return (
                <div
                  md={4}
                  className="item-container-tetimonials"
                  style={{
                    padding: "10px",
                    minHeight: "160px",
                    marginTop: "80px",
                  }}
                >
                  <Card
                    title={
                      <div className="testimonial-item-header">
                        <Image
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt0yboM1y3PKIke01cHJpc0V7j-LAmoZ4PkQ&s"
                          }
                          className="testimonials-badge"
                        />
                        <p className="testimonials-name">{item.name}</p>
                        <div style={{ position: "absolute", right: "10px" }}>
                          {" "}
                          <Image src={star} className={"star-icon"} />
                        </div>
                      </div>
                    }
                    style={{
                      width: 400,
                    }}
                  >
                    <div className="testimonials-title-holder">
                      <h3 className="testimonilas-title">{item.title}</h3>
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "75px",
                        }}
                      >
                        <p>{item.time}</p>
                      </div>
                    </div>
                    <Divider />
                    <div className="testimonials-description">
                      <p className="testimonials-review">
                        {item.review.length < 100
                          ? item.review
                          : item.review.slice(0, 80) + "..."}
                      </p>
                    </div>
                  </Card>
                </div>
              );
            })}
          </Carousel>
        </Container>
      </BrowserView>
      <MobileView>
        <Container
          className="category-container"
          style={{ marginTop: "350px" }}
        >
          <div>
            <h1>{props.title}</h1>
            <p>{props.description}</p>
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-product"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            itemClass="carousel-item-padding-40-px"
            customButtonGroup={<ButtonGroup />}
            autoPlay={false}
          >
            {testimonials.map((item) => {
              return (
                <div
                  md={4}
                  className="item-container-tetimonials"
                  style={{
                    minHeight: "160px",
                    marginTop: "80px",
                  }}
                >
                  <Card
                    title={
                      <div className="testimonial-item-header">
                        <Image
                          src={
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt0yboM1y3PKIke01cHJpc0V7j-LAmoZ4PkQ&s"
                          }
                          className="testimonials-badge"
                        />
                        <p className="testimonials-name">{item.name}</p>
                        <div style={{ position: "absolute", right: "10px" }}>
                          {" "}
                          <Image src={star} className={"star-icon"} />
                        </div>
                      </div>
                    }
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="testimonials-title-holder">
                      <h3 className="testimonilas-title">{item.title}</h3>
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "75px",
                        }}
                      >
                        <p>{item.time}</p>
                      </div>
                    </div>
                    <Divider />
                    <div className="testimonials-description">
                      <p className="testimonials-review">
                        {item.review.length < 100
                          ? item.review
                          : item.review.slice(0, 80) + "..."}
                      </p>
                    </div>
                  </Card>
                </div>
              );
            })}
          </Carousel>
        </Container>
      </MobileView>
    </>
  );
}
